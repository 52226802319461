<template>
  <div class="hamburger-menu">
    <div class="hamburger-menu__navigation-links">
      <ul class="level0">
        <submenu-items v-for="item in shopListItems" :key="item.url_path" :category="item" />
      </ul>
    </div>
    <internal-link></internal-link>
  </div>
</template>

<script>
import InternalLink from './InternalLink'
import SubmenuItems from '@/theme/components/RightNavigation/Menu/Layout/SubmenuItems'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'

export default {
  name: 'HamburgerMenu',
  computed: {
    items () {
      return this.$store.getters['ui/menu']
    },
    shopListItems () {
      return sortBy(filter(this.items, { include_in_menu: true, is_active: true }), ['position'])
    }
  },
  methods: {
    closeRightBar () {
      this.$store.commit('ui/setActiveComponent', {})
    }
  },
  components: {
    InternalLink,
    SubmenuItems
  }
}
</script>
