<template>
  <li class="level0 with-title">
    <template v-if="childrenData.length">
      <div class="header-title" v-b-toggle="`menu-acc.${category.id}`">
        <span class="link-label">{{category.name}}</span>
        <span class="arrow-down-end"></span>
      </div>
      <b-collapse
      :id="`menu-acc.${category.id}`"
      accordion="hamburger-menu"
      role="tabpanel">
        <ul class="nav flex-column level1">
          <li v-for="flatted in childrenData" :key="flatted.id" class="level1">
            <template v-if="flatted.childrenData && flatted.childrenData.length">
              <div class="header-title with-subnav">
                <span class="link-label">{{flatted.name}}</span>
              </div>
              <ul class="level2 nav flex-column">
                <li class="level2" v-for="subDeep in flatted.childrenData" :key="subDeep.url_path">
                  <link-manager :category="subDeep" :label="subDeep.name" class="header-title" />
                </li>
              </ul>
            </template>
            <!-- single link -->
            <link-manager v-else :category="flatted" :label="flatted.name" class="header-title" />
          </li>
        </ul>
      </b-collapse>
    </template>
    <template v-else>
      <link-manager :category="category" :label="category.name" :class="[category.id === 44 ? 'header-title sales' : 'header-title']" />
    </template>
  </li>
</template>

<script>
import sortBy from 'lodash/sortBy'
import LinkManager from '@/theme/components/Header/SubMenu/Layout/LinkManager'

export default {
  name: 'WithTitle',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    childrenData () {
      if (this.category && this.category.childrenData) {
        return sortBy(this.category.childrenData, ['position'])
      } else {
        return []
      }
    }
  },
  components: {
    LinkManager
  }
}
</script>
