<template>
  <div class="hamburger-menu__internal-links">
    <ul class="hamburger-menu__internal-links-nav nav flex-column">
      <li class="burger-my-account">
        <button class="btn btn-none bcm-link bcm-link-secondary" @click.prevent="goToAccountPage()">{{ $t('my-account') }}</button>
      </li>
      <li>
        <button class="btn btn-none bcm-link bcm-link-secondary" @click.prevent="goToLocale()">{{ currentLang }}</button>
      </li>
      <li>
        <router-link :to="{ name: 'StoreLocator' }" class="bcm-link bcm-link-secondary">{{ $t('route.store-locator') }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InternalLink',
  computed: {
    currentLang () {
      return this.$store.getters['ui/getCurrentLang']
    }
  },
  methods: {
    goToAccountPage () {
      let componentToLoad
      if (this.$store.state.user.token) {
        componentToLoad = import('../Account/AccounNavigation')
      } else {
        componentToLoad = import('../Auth/Login')
      }
      this.goTo(componentToLoad)
    },
    goToLocale () {
      const componentToLoad = import('../Language/ChooseLanguage')
      this.goTo(componentToLoad)
    },
    goTo (componentToLoad) {
      return this.$store.dispatch('ui/loadActiveComponent', { component: componentToLoad })
    }
  }
}
</script>
