<template>
  <li class="level0 without-title">
    <template v-if="childrenData.length">
      <div class="header-title" v-b-toggle="`menu-acc.${category.id}`">
        <span class="link-label">{{category.name}}</span>
        <span class="arrow-down-end"></span>
      </div>
      <b-collapse
      :id="`menu-acc.${category.id}`"
      accordion="menu-collapse"
      role="tabpanel">
        <ul class="level1 nav nav-column flex-column">
          <li v-for="flatted in childrenData" :key="flatted.id" class="level1">
            <link-manager :category="flatted" :label="flatted.name" class="header-title" />
          </li>
        </ul>
      </b-collapse>
    </template>
    <template v-else>
      <link-manager :category="category" :label="category.name" class="header-title" />
    </template>
  </li>
</template>

<script>
import WithTitle from '@/theme/components/RightNavigation/Menu/Layout/WithTitle'

export default {
  name: 'WithoutTitle',
  mixins: [WithTitle]
}
</script>
