<template>
  <component :is="loadSubmenuByLayout" :category="category"></component>
</template>

<script>
import find from 'lodash/find'
import WithTitle from './WithTitle' // default
import WithoutTitle from './WithoutTitle'
import SingleColumn from './SingleColumn'

const layout = {
  'with-title': 'WithTitle',
  'without-title': 'WithoutTitle',
  'single-column': 'SingleColumn'
}

export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    loadSubmenuByLayout () {
      let componentToLoad = WithTitle
      let componentMatch = find(this.category.custom_attributes, { attribute_code: 'custom_frontend_layout' })
      if (componentMatch && componentMatch.value && layout[componentMatch.value]) {
        componentToLoad = layout[componentMatch.value]
      }
      return componentToLoad
    }
  },
  components: {
    WithTitle,
    WithoutTitle,
    SingleColumn
  }
}
</script>
